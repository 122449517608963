function getPathFromId_Components(id){
    return id.split('_').slice(1);
}

function getPathFromId(id){
    return getPathFromId_Components(id).join('.');
}

getPathFromId.Components = getPathFromId_Components

export default getPathFromId;