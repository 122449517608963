import "./ticket-page.scss";

import InternalLayout from '../../components/layouts/InternalLayout';
import useTitle from "../../util/useTitle";
import BaseForm from './BaseForm';


export default function TicketPage() {
    useTitle("Ayuda");

    return (
        <InternalLayout>
            <BaseForm />
        </InternalLayout>
    );
}