import { useMemo } from "react";
import { t } from "@lingui/macro";

import dfsSearch from "./dfsSearch";
import { appendError } from "./formValidation";
import { getObject } from "./mapObject";
import Jnx, { parseJnxExpr } from "./jnx";


const REQUIRED_JNX = new Jnx("$isEmpty($value) ? 'es requerido.' : false");
const REQUIRED_IF_JNX = (jnx, invert) => {
    jnx = parseJnxExpr(jnx);
    const castFn = invert ? 'isFalsy' : 'isTruthy';
    jnx.expr = `($${castFn}(${jnx.expr}) and $isEmpty($value)) ? 'es requerido.' : false`;
    return new Jnx(jnx);
};

const RequiredEnum = {
    ifVisible: 'ifVisible',
    always: 'always',
}


export default function useJnxFormValidation(schema, uiSchema, formFieldVisibilityRef){
    return useMemo(() => {
        const validations = parseValidations(schema, uiSchema);

        return (validations.length ? ((formData, errors, scope, formFields) =>
            runJnxValidation(validations, formFieldVisibilityRef, formData, errors, scope, formFields || {})
        ) : undefined);

    }, [uiSchema]);
}

function runJnxValidation(validations, formFieldVisibilityRef, formData, errors, scope, formFields){
    validations.forEach(({path: acPath, jnx, trigger}) => {
        acPath.getAll(formData).forEach(([path, value]) => {
            const isVisible = formFields[path];
            const shouldValidate = (
                (
                    trigger === RequiredEnum.always
                ) || (
                    trigger === RequiredEnum.ifVisible && isVisible
                )
            );
            console.log(`path:${path}, trigger:${trigger}, isVisible:${isVisible}, shouldValidate:${shouldValidate}`)
            let result = null;
            try {
                result = shouldValidate ? jnx.eval(formData, path, {
                    ...scope,
                    isVisible,
                    trigger,
                    value,
                    rootFormData: formData
                }) : false;
            } catch (e) {
                appendError(errors, path, t`Error evaluating requirement`);
            }
            if (result) {
                appendError(errors, path, result);
            }
        })
    });
    return errors;
}


/** Represents an object path that is aware of arrays in the path and can be used to list all reachable objects.
 */
class ArrayConsciousObjectPath {
    constructor(segments){
        this.segments = segments || [];
    }

    append(component) {
        const segments = this.segments.slice();
        const last = segments[segments.length - 1] || '';
        const separator = last.length ? '.' : '';
        const current = `${last}${separator}${component}`;
        if (!segments.length) { 
            segments.push(current);
        } else {
            segments[segments.length - 1] = current;
        }
        return new ArrayConsciousObjectPath(segments);
    }

    appendArray() {
        return new ArrayConsciousObjectPath(this.segments.concat(''));
    }

    getAll(data) {
        const list = [];
        const N = this.segments.length;
        const N1 = N - 1;
        dfsSearch([
            ['', 0, data]
        ], ([parentPath, segmentIdx, searchData]) => {
            const segment = this.segments[segmentIdx];
            let segmentPath = `${parentPath}${parentPath.length ? '.' : ''}${segment}`;
            const segmentData = getObject(searchData, segment);

            if (segmentData && Array.isArray(segmentData) && segmentIdx < N1) {
                const nextIdx = segmentIdx + 1;
                const segmentSep = segmentPath.length ? '.' : ''
                return segmentData.map((item, idx) => [`${segmentPath}${segmentSep}${idx}`, nextIdx, item]);
            } else if (segmentIdx === N1) {
                if (segmentPath.endsWith('.')) segmentPath = segmentPath.substring(0, segmentPath.length - 1);
                list.push([segmentPath, segmentData]);
            }
        });

        return list;
    }

}


/** Parses the validation expressions in the given schema and uiSchema pair.
 * @param {object} schema - the jsonschema to parse
 * @param {object} uiSchema - the associated ui schema to parse
 * @returns {array} array of validations
 */
function parseValidations(schema, uiSchema){
    const validations = [];

    dfsSearch([[
        new ArrayConsciousObjectPath(['']), '', schema, uiSchema
    ]], ([currentPath, parentName, currentSchema, currentUISchema]) => {
        if (!currentUISchema) return;

        const { type, properties, items, title } = currentSchema;
        const name = (title || parentName).replaceAll('*', '').trim();
        const {
            'akc:requiredIfVisible': requiredIfVisible,
            'akc:validateTrigger': validateTrigger = RequiredEnum.ifVisible,
            'akc:required': required,
            'akc:requiredIf': requiredIfExpr,
            'akc:requiredUnless': requiredIfNotExpr,
            'akc:validate': validateExpr,
        } = currentUISchema;

        if (required) {
            tryAddValidation(validations, currentPath, validateTrigger, () => REQUIRED_JNX);
        }

        if (requiredIfVisible) {
            tryAddValidation(validations, currentPath, RequiredEnum.ifVisible, () => REQUIRED_JNX);
        }

        if (requiredIfExpr) {
            tryAddValidation(validations, currentPath, validateTrigger, () => REQUIRED_IF_JNX(requiredIfExpr));
        }

        if (validateExpr) {
            tryAddValidation(validations, currentPath, validateTrigger, () => new Jnx(validateExpr));
        }

        if (requiredIfNotExpr) {
            tryAddValidation(validations, currentPath, validateTrigger, () => REQUIRED_IF_JNX(requiredIfNotExpr, true));
        }

        switch(type) {
            case "object": {
                return Object.entries(properties).map(([propName, propSchema]) => [
                    currentPath.append(propName),
                    name,
                    propSchema,
                    currentUISchema[propName]
                ]);
            }
            case "array": {
                return [[
                    currentPath.appendArray(),
                    name,
                    items,
                    currentUISchema.items
                ]];
            }
            default: break;
        }
    });

    return validations;
}

function tryAddValidation(list, path, trigger, jnxFn){
    try{
        const jnx = jnxFn();
        list.push({ path, jnx, trigger });
    } catch(e){
        console.error("Error parsing jnx validation", e);
    }
}