import React from 'react';
import { Card, Alert, CardBody, CardHeader, ListGroup, ListGroupItem, Button } from 'reactstrap';
import { useHistory } from 'react-router';

import useResourceLoader from "../util/useResourceLoader";
import useTitle from "../util/useTitle";
import Loader from '../components/Loader';
import ManageFaqApi from '../api/ManageFaqApi';
import InternalLayout from '../components/layouts/InternalLayout';
import Notification from '../components/Notification';
import { TICKET_FORM } from '../constants';


const DOCUMENTS = [
];


const VIDEOS = [
];

export function Faq() {
  const history = useHistory();
  useTitle("Ayuda");

  const [listFaqs, loadingFaqs] = useResourceLoader(
    () => ManageFaqApi.getFaqs(),
    [], null);

  return (
    <InternalLayout>
      <div>
        {DOCUMENTS.length || VIDEOS.length ? (<>
        <h1>Documentaci&oacute;n</h1><br />
        <h4>Aquí puedes encontrar los documentos de apoyo para Fast Fleet </h4>
        {DOCUMENTS.length ? (<>
          <h3>Documentos</h3>
          <ListGroup>
            {DOCUMENTS.map(({title, url}, idx) => (<ListGroupItem key={idx}>
              <a href={url} target="_blank" rel="noreferrer">{title}</a>
            </ListGroupItem>))}
          </ListGroup>
          <br />
        </>) : null}
        <h3>Videos de Entrenamiento</h3>
        <div className="d-flex flex-wrap flex-row">
          {VIDEOS.map(({title, url}, idx) => (<Card className="mb-4">
            <CardBody key={idx}>
              <h4 className="text-default">{title}</h4>
              <iframe width="560" height="315" src={url} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </CardBody>
          </Card>))}
          {!(VIDEOS?.length) ? (
            <Notification color="warning">Al momento no se encuentra material en esta sección. Verifique de nuevo pronto.</Notification>
          ) : ""}
        </div>
        <br />
        </>) : null}
      </div>
      <br/>
      {loadingFaqs ? <Loader /> : (
        listFaqs?.length ?
          listFaqs?.map((faq, key) => (
            <FaqCard key={key} faq={faq} />
          )) :
          <Alert color="info">No faqs have been created yet</Alert>
      )}
      {TICKET_FORM ? <>
        <h3>Crear un Ticket</h3>
        <h4>¿Necesitas ayuda directo al equipo? Crea un ticket.</h4>
        <Button onClick={() => {history.push(`/create-ticket`)}} color="info">
          Crear Ticket
        </Button>
      </> : null}
    </InternalLayout>
  )
};

export default Faq;

function FaqCard({
  faq
}) {
  const {
    content,
    createdAt,
  } = faq || {};

  return (
    <Card style={{ padding: 1, marginBlockEnd:20, border: 0, boxShadow: 0, borderBottom: '1.5px solid #ccc' }}>
      <CardHeader>
        <span>{formatDate(createdAt)}</span>
      </CardHeader>
      <CardBody>
        {HTMLCodeDisplay(content)}
      </CardBody>
    </Card>
  );
}

const HTMLCodeDisplay = (htmlCode) => {
  return <div dangerouslySetInnerHTML={{ __html: `<div style="font-family: 'Inter'">${htmlCode}</div>` }} />;
};

const formatDate = (dateStr) => {
  if (!dateStr)
    return "";
  const date = new Date(dateStr);
  const monthAbbr = date.toLocaleString('default', { month: 'short' }).toUpperCase();
  const formattedDate = `${monthAbbr}-${date.getDate()}-${date.getFullYear()}`;
  return formattedDate;
}