import { useCallback, useMemo, useRef, useState } from 'react';
import { Card, CardHeader } from 'reactstrap';

import FileUploadsInput from './FileUploadsInput';
import { TICKET_FORM } from '../../constants';


const FORM_FIELDS = [
    { name: 'actionType', hidden: true, value:'Q2FzZXM='},
    { name: 'returnURL', hidden: true, props: {value: document.location.origin}},
    { name: 'First Name', required: true, props: { type:'text', maxLength:'120' } },
    { name: 'Contact Name', label: "Last Name", required: true, props: { type:'text', maxLength:'120' } },
    { name: 'Email', required: true, props: { type:'text', maxLength:'120' } },
    { name: 'Subject', required: true, props: { type:'text', maxLength:'255' } },
    { name: 'Description', required: true, Component: "textarea", props: { maxLength:'3000', width:'250', height:'250' } },
    // { name: 'attachment_${idx}', label: 'Attachment', Component: FileUploadsInput, props: { maxCount: 5 } },
];

function BaseForm(){
    const formRef = useRef();
    const [_validationErrors, setValidationErrors] = useState(undefined);
    const validationErrors = _validationErrors || {};
    const [resetCounter, setResetCounter] = useState(0);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const fields = useMemo(() => [
        { name: 'xnQsjsdp', hidden: true, value: TICKET_FORM.xnQsjsdp},
        { name: 'xmIwtLD', hidden: true, value: TICKET_FORM.xmIwtLD},
        { name: 'xJdfEaS', hidden: true, value:''},    
        ...FORM_FIELDS.map(field => ({
            ...field,
            Component: field.Component ?? 'input',
            label: field.label ?? field.name,
        }))
    ], [TICKET_FORM, FORM_FIELDS])

    const handleSubmit = useCallback((e) => {
        try {
                // e.preventDefault(); // Stop the default form submission
            const formElement = formRef.current;
            const errors = validateMandatoryFields(formElement, fields);
            setValidationErrors(errors.reduce((_, x) => {
                _[x.name] = x.message;
                return _;
            }, {}));

            if (errors?.length) {
                e.preventDefault();
                return false;
            }

            setSubmitDisabled(true);
        } catch (error) {
            e.preventDefault();
            setValidationErrors([{name: 'submit', message: error.message}]);
        }
    }, [validateMandatoryFields, fields]);

    const handleReset = useCallback(() => {
        formRef.current.reset()
        setResetCounter((new Date()).getTime());
    }, [])


    return (
        <Card className='ticket-page'>
            <CardHeader>Create Ticket</CardHeader>
            <form
                key={resetCounter}
                ref={formRef}
                action={TICKET_FORM.action}
                method="POST"
                onSubmit={handleSubmit}
            >
                {fields.filter(x => x.hidden).map(({ name, value }, idx) => (
                    <input key={idx} type="hidden" name={name} value={value} />
                ))}
                {/* <input type="hidden" id="property(module)" value="Cases"/>
                <input type="hidden" id="dependent_field_values_Cases" value="&#x7b;&quot;JSON_VALUES&quot;&#x3a;&#x7b;&#x7d;,&quot;JSON_SELECT_VALUES&quot;&#x3a;&#x7b;&#x7d;,&quot;JSON_MAP_DEP_LABELS&quot;&#x3a;&#x5b;&#x5d;&#x7d;"/> */}
                <table border='0' cellSpacing='0' className='form-table'>
                    <tbody>
                        {fields.filter(x => !x.hidden).map(({ label, name, Component, props }, idx) => (
                            <tr key={idx}>
                                <td className='label-text nowrap' align='left'>
                                    {label}&nbsp;&nbsp;
                                    {validationErrors[name] ? <div key={idx} className='alert'>{validationErrors[name]}</div> : null}
                                </td>
                                <td align='left' >
                                    <Component name={name} {...props} className={`${props.className ?? ''} ${validationErrors[name] ? 'validation-error' : ''}`} />
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td className='label-text' />
                            <td className="buttonPanel">
                                <button type='submit' disabled={submitDisabled} className='btn btn-primary' >Submit</button>
                                &nbsp; &nbsp; 
                                <button type='button' disabled={submitDisabled} className='btn btn-secondary' onClick={handleReset} >Reset</button>
                                {validationErrors.submit ? <div className='alert'>{validationErrors.submit}</div> : null}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </Card>
    )
}


const WHITESPACE_RE = /^\s+|\s+$/g
const EMAIL_RE = /^([\w_][\w\-_.+'&]*)@(?=.{4,256}$)(([\w]+)([-_]*[\w])*[.])+[a-zA-Z]{2,22}$/;


export function validateMandatoryFields(
    formElement, fields
) {
    const errors = [];

    if (!formElement) return errors;
    const requiredFields = fields.filter(x => x.required);
 

    requiredFields.forEach(field => {
        const fieldObject = formElement[field.name];
        if (!fieldObject) return; // ignore missing

        if (((fieldObject.value).replace(WHITESPACE_RE, '')).length === 0) {
            errors.push({name: field.name, message: `${field.label} cannot be empty`});
            fieldObject.focus();
        } else if (fieldObject.name === 'Email') {
            if (!fieldObject.value.match(EMAIL_RE)) {
                errors.push({name: field.name, message: 'Enter a valid email-Id'});
                fieldObject.focus();
            }
        }
    })

    return errors;
}

export default BaseForm;