import { useCallback, useMemo, useState } from 'react';
import { interpolate } from '../../util/mapObject';


function FileUploadsInput({
    name,
    maxSize=20,
    maxCount=5
}){
    const [files, setFiles] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const handleClick = useCallback((e) => {
        const inputs = Array.prototype.slice.call(e.currentTarget.parentNode.querySelectorAll("input[type=file]"));
        const emptyInput = inputs.filter(x => x.value === '').shift();
        if (emptyInput) emptyInput.click();
        // zsOpenFileBrowseAttachment(e);
    }, [])
    const handleRemove = useCallback((e, inputName) => {
        e.preventDefault()
        const fui = e.currentTarget.closest(".file-uploads-input");
        const inputs = Array.prototype.slice.call(fui.querySelectorAll("input[type=file]"));
        const namedInput = inputs.filter(x => x.name === inputName).shift();

        if (namedInput) {
            namedInput.value = '';
            setFiles(files => (files || []).filter(x => x.name !== inputName));
        }
    }, [])
    const handleChange = useCallback((e) => {
        const { name, files: eFiles, attributes: {"data-idx": {value: dataIndex}} } = e.currentTarget;
        const file = (eFiles || [])[0]

        if (!file) return;

        setFiles(files => [
            ...(files || []).filter(x => x.name !== name),
            { name, file }
        ])
    }, [])

    const fileFields = useMemo(() => {
        const fields = [];
        for(let idx = 1; idx <= maxCount; idx += 1) {
            fields.push(<input
                key={idx}
                data-idx={idx}
                className="wtcuploadinput"
                type='file'
                name={interpolate(name, { idx })}
                onChange={handleChange}
            />);
        }

        return fields;
    }, [maxCount, handleClick, handleChange, interpolate])

    return <div className='file-uploads-input'>
        <div className="clboth">
            {(files || []).map(({name, file}) => (
                <div key={name}>
                    {file.name}
                    <button onClick={(e) => handleRemove(e, name)} className='btn x-button'>X</button>
                </div>
            ))}
        </div>

        {(files?.length ?? 0) < maxCount ? (
            <button type="button" onClick={handleClick} className="btn btn-secondary">
                Attach files
            </button>
        ) : null}
        {fileFields}
        {/* <div style={{color: 'black', fontSize: '8px', float: 'left'}}>
            Each of your file(s) can be up to {maxSize}MB in size.
        </div> */}
        <div className="flavor" >
            You can attach as many as {maxCount} files at a time.
        </div>
        <div id='zsFileBrowseAttachments' />
    </div>
}


export default FileUploadsInput;